.uiButtons{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    /* grid-template-columns: repeat(7, minmax(100px, 1fr)); */
    grid-gap: 5px;
    align-items: end;
}
.control-instruction{
    position: absolute;
    bottom: 50px;
    right: 0;
    background-color: #4d5257;
    color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 4px;
    text-align: right;
}

.show-hide-instruction {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #4d5257;
    color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 4px;
    text-align: left;

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}