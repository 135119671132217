body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

* {
  font-family: myFirstFont;
}
canvas {
  width: 100%;
}
.image {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 100px;
  width: 100px;
  z-index: 1;
}
.title {
  font-weight: 600;
  font-size: 40px;
  margin-left: 5%;
  z-index: 99999999999;
}

.pagename {
  color: #f8a11c;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  margin-top: 60px;
  margin-left: 12%;
}
.main-image {
  height: 450px;
  /* width: 100%; */
  margin-left: 20px;
}
.header {
  display: flex;
  margin-top: -25px;
  margin-bottom: 48px;
}

.mainimage {
  width: 95%;
  margin-left: 3%;
}

.pricebox {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgb(26 26 26 / 16%);
  border-radius: 12px;
  width: 70%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding: 22px;
  font-weight: 400;
  font-size: 18px;
}

.pricebox1 {
  background: #000;
  color: #fff;
  border-radius: 12px;
  width: 70%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 22px;
  font-weight: 400;
  box-shadow: 0px 0px 30px rgba(26, 26, 26, 0.16);
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}

.pricebox2 {
  background: #f8a11c;
  color: #fff;
  border-radius: 12px;
  width: 70%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 22px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 0px 30px rgba(26, 26, 26, 0.16);
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.priceamount {
  float: right;
  font-weight: 700;
}

.modeltitle {
  font-weight: 600;
  font-size: 24px;
  margin-left: 13%;
}

.subVanitytitle {
  font-weight: 600;
  font-size: 16px;
  margin-left: 13%;
  text-transform: uppercase;
  text-align: initial;
  margin-bottom: 9px;
}

.subcircletitle {
  font-weight: 600;
  font-size: 13px;
}

.modalimg {
  width: 77%;
  height: 66px;
  border-radius: 20px;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  box-shadow: 0px 10px 30px rgb(26 26 26 / 16%);
  margin-right: auto;
  display: block;
}

.modalimgshape {
  width: 77%;
  border: solid 1px #e6e6e6;
  border-radius: #f5f5f5;
  height: 115px;
  border-radius: 12px;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.modalPlumbingshape {
  width: 77%;
  border: solid 1px #e6e6e6;
  border-radius: 20px;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Optiondesign {
  position: absolute;
  margin-top: -60px;
  margin-left: 60px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.optionspan {
  font-weight: 600;
  font-size: 34px;
  display: block;
}

.optionbox {
  margin-bottom: 10px;
  cursor: pointer;
}

.shapeimge {
  width: 125px;
  display: inline-block;
  margin-top: 15px;
  margin-left: 30px;
}

.titleshape {
  margin-top: -59px;
  display: block;
  margin-left: 200px;
  font-weight: 600;
  font-size: 22px;
}

.checkimg {
  width: 35px;
  display: block;
  float: right;
  margin: -30px auto;
}

.circle {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  border: 1px solid #fff;
}

.colorcircle {
  text-align: center;
  width: 70px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
}

.maincirlediv {
  margin-left: 12%;
}

.accessoriesbtn {
  border: solid 1px #f8a11c;
  width: 150px;
  height: 50px;
  border-radius: 12px;
  background-color: #f8a11c;
  display: inline-block;
}

.accessoriesbtn2 {
  border: solid 1px #f8a11c;
  width: 150px;
  text-align: center;
  height: 50px;
  border-radius: 10px;
  display: inline-block;
  background-color: #f8a11c;
}

.accessoriesbtntitle {
  margin-left: 7px;
  font-weight: 600;
  color: #fff;
  display: inline-block;
}

.accessoriescheck {
  display: inline-block;
  width: 25px;
  float: right;
  margin-top: 13px;
  margin-right: 14px;
}

.togletitle {
  color: #5a6670;
  font-weight: 400;
  font-size: 16px;
  margin-left: 4%;
}

.toglefic {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 9%;
  padding-right: 25px;
}

.togle {
  float: right;
}

.electricaltitle {
  display: block;
  font-size: 16px;
  margin-top: 9px;
  font-weight: 600;
  text-align: start;
  margin-left: 12px;
}
.electricaldetail {
  color: #5a6670;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  margin-left: 12px;
  margin-top: -10px;
}

.ulchange {
  margin-top: -12px !important;
  color: #5a6670;
}

.boxspan {
  font-weight: 600;
  font-size: 24px;
  float: right;
}
canvas {
  /* width: 100% !important;
height: 100% !important; */
}
#sidebarscroll::-webkit-scrollbar {
  width: 5px;
}

#sidebarscroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#sidebarscroll::-webkit-scrollbar-thumb {
  background: #888;
}

#sidebarscroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .model-view {
  display: flex;
  width: 100%;
} */
.model-graphic {
  width: 65%;
  position: relative;
}
.side-bar-Menu {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  /* margin-top: 100px; */
  padding-bottom: 30px;
}

.model-direction {
  width: 30%;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: black;
  opacity: 0.7;
  z-index: 999;
}

.loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 960px) {
  .model-view {
    flex-direction: column;
  }
  .side-bar-Menu {
    height: 100vh;
    margin-top: auto;
  }
  .model-graphic {
    width: 100%;
  }
  .model-direction {
    width: 100%;
  }
}




.maincirlediv {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap; 
  gap: 20px; 
  padding: 10px; 
}

.subVanitytitle {
  margin-top: 20px; 
  font-size: 1.2rem; 
}

h3.modeltitle, h5.subVanitytitle {
  margin-bottom: 10px; 
}

.maincirlediv > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modeltitle {
  font-size: 1.5rem; 
  color: #333;
  text-align: left; 
}

.subVanitytitle {
  font-size: 1.2rem; 
  color: #555; 
  text-align: left; 
}
/* Form Container */
.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Input Fields */
.input-field {
  margin-bottom: 20px;
}

/* Labels */
.input-label {
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

/* Input Box Styles */
.input-box {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color:transparent;
  box-sizing: border-box;
}

.input-box:focus {
  border-color: #007BFF;
  outline: none;
}

/* Price Box */
.pricebox {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.boxspan {
  color: #28a745;
  font-size: 22px;
}

/* Submit Button */
.submit-button {
  background-color: #007BFF;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:focus {
  outline: none;
}